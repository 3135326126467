@import "~react-multi-carousel/lib/styles.css";

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("public/static/fonts/MontserratAlternates-Regular.otf") format("otf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.element {
  width: 20px;
  height: 20px;
  margin: 10px;
  position: relative;
  padding: 10px;
}
.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/*.carousel-container {*/
/*  justify-content: center;*/
/*}*/

.react-multi-carousel-list {
  padding-bottom: 10px;
}

/*react srcoll component*/
.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.menu-wrapper {
  height: 98%;
}

.menu-wrapper--inner {
  height: 100%;
}

.menu-item-wrapper {
  height: 100%;
}

.MuiMenuItem-root {
  font-size: calc(10px + 0.2vw) !important;
}

/*amplify cognito components*/
:root {
  --amplify-primary-color: rgb(37, 37, 37);
  --amplify-primary-tint: white;
  --amplify-primary-shade: white;
  --amplify-grey: rgb(37, 37, 37);
  --amplify-light-grey: white;
  --amplify-background-color: rgb(211, 211, 211, 0.9);
}

amplify-authenticator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  height: 100vh;
  margin-right: 15px;
}

amplify-sign-in {
  --footer-color: white;
}

@media only screen and (max-width: 1024px) {
  amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    margin-right: 0px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 850px) {
  amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    margin-right: 0px;
    margin-top: 25px;
  }

  /*#layout {*/
  /*  padding-left: env(safe-area-inset-left);*/
  /*  padding-right: env(safe-area-inset-right);*/
  /*}*/
}

@media only screen and (orientation: portrait) and (max-width: 450px) {
  amplify-sign-in {
    width: 95vw;
  }
}
